
import { Component, Mixins } from "vue-property-decorator";
import { disbursement, merchant } from "@/api/queries/merchants";
import { MerchantDisbursement, MerchantDetails } from "@/@types/Merchant";
import { Nullable } from "@/@types/BaseTypes";
import { updateMerchantAddressable } from "../api/mutations/merchant";
import moment from "moment-mini";
import NotifierMixin from "@/mixins/NotifierMixin";

@Component({
  apollo: {
    disbursement: {
      query: disbursement,
      variables() {
        return { appUuid: this.$route.params.merchantUuid };
      },
      update({
        merchantDisbursement: { disbursement },
      }: {
        merchantDisbursement: { disbursement: MerchantDisbursement };
      }) {
        return disbursement;
      },
    },
    details: {
      query: merchant,
      variables() {
        return { appUuid: this.$route.params.merchantUuid };
      },
      update({ merchant }: { merchant: MerchantDetails }) {
        this.hideAddress = !merchant.addressable;

        return merchant;
      },
    },
  },
})
export default class MerchantRegistrationDetails extends Mixins(NotifierMixin) {
  private disbursement: Nullable<MerchantDisbursement> = null;

  private details: Nullable<MerchantDetails> = null;

  private hideAddress = false;

  private humanizedDate(date: string) {
    return moment(date).format("MMMM DD, YYYY");
  }

  private get disbursementAttachments() {
    if (!this.disbursement) {
      return [];
    }

    return [
      {
        label: "Company Profile",
        url: this.disbursement.companyProfileUrl,
      },
      {
        label: "BIR-2303",
        url: this.disbursement.birUrl,
      },
      {
        label: "SEC/DTI Registration",
        url: this.disbursement.dtiUrl,
      },
      {
        label: "Business Permit",
        url: this.disbursement.businessPermitUrl,
      },
      {
        label: "Government ID",
        url: this.disbursement.governmentIdUrl,
      },
    ];
  }

  private get filteredAttachments() {
    return this.disbursementAttachments.filter(
      ({ url }: { url: Nullable<string> }) => !!url
    );
  }

  private openFile(url: string) {
    window.open(url, "_blank");
  }

  private updateAddressable() {
    this.$apollo
      .mutate({
        mutation: updateMerchantAddressable,
        variables: {
          input: {
            appUuid: this.$route.params.merchantUuid,
            addressable: !this.hideAddress,
          },
        },
      })
      .then(() => {
        this.showSuccessAlert("Merchant Config Updated");
        this.$apollo.queries.details.refetch();
      })
      .catch((e) => {
        this.showErrorAlert(e.message);
      });
  }
}
